import React from "react";

const NextdoorReviews = () =>{

    const UserPhoto = "https://firebasestorage.googleapis.com/v0/b/imagenessanti-2a052.appspot.com/o/no_photo.png?alt=media&token=93dfe8bc-7394-4ace-975d-795f2ba3921b"
        const ReviewsData = [
            {
                name: "J. V.",
                description: "Electrician. Wow.  I’ve contacted 5 electricians and they are either not getting back to me or booked until October.  Anyone know an electrician, are an electrician who has some time.  I have a circuit out.  Thanks Jeanne.",
                date: "4 Aug 21",
            },
            {
                name: "L. L.",
                description: "Yep! Chavarria’s electric, Luis is experienced, reliable and responsible! 510-258-1267 He has done several jobs for me and my clients.",
                date: "4 Aug 21",
            },
            {
                name: "H. K.",
                description: "Chavarria’s Electric. Luis and his guys are terrific. They added 2 outdoor outlets for a fair price, quickly and totally neatly. Would hire them again for any other electrical needs we have. Super nice and professional. Thank you, Luis!",
                date: "19 Jun 21",
            }
        ]

    return(
        <section>
                <a
                className="block w-[300px] mx-auto"
                href="https://nextdoor.com/pages/chavarrias-electric-oakland-ca/"
                target="_blank"
                rel="noreferrer"
                >
                    <img
                    src="https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/directorios%2Fnextdoor.png?alt=media&token=52453f70-a4c9-4598-8356-eb0cb83b1d7d"
                    alt="nextdoor_logo"
                    className="w-[90%] mx-auto"
                    />
                </a>
            <section className="w-4/5 mx-auto flex flex-wrap justify-between pb-10 gap-5">
                {
                    ReviewsData.map((intem, index) =>{
                       return(
                         <article className="w-[300px] h-auto bg-[#F3F3F3] p-5 flex flex-col rounded-3xl">

                            <div className="flex items-center">
                               <div className="w-[40px]">
                                    <img 
                                    src={UserPhoto}
                                    alt="user_no_photo"
                                />
                             </div> 
                             <div className="flex flex-col">
                                <span className="pl-3">{intem.name}</span>
                                <p className="text-[13px] pl-3">{intem.date}</p>
                             </div>
                            </div>

                            <div>
                                <p className="leading-6">{intem.description}</p>
                            </div>
                        </article>
                       )
                    })
                }
               
        </section>  
        </section>
    )
}

export default NextdoorReviews